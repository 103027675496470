<template>
    <div class="app-prettify">
        <pre class="prettyprint" :class="[language]" v-html="code"></pre>
    </div>
</template>

<script>
import './prettify.js'
import './prettify-sunburst.css'

export default {
    props: {
        code: { required: true },

        lang: { type: String, default: 'json' },
    },

    mounted() {
        this.prettyPrint()
    },

    updated() {
        this.prettyPrint()
    },

    methods: {
        prettyPrint() {
            const $prettyprint = this.$el.querySelector('.prettyprint')

            if ($prettyprint) {
                $prettyprint.classList.remove('prettyprinted')

                window.prettyPrint(() => {
                    console.log('Ready...')
                }, this.$el)
            }
        },
    },

    computed: {
        language() {
            return `language-${ this.lang }`
        },
    },
}
</script>

<style lang="scss">
.app-prettify {
    pre {
        &.prettyprint {
            width: 100%;
            margin: 0;
            padding: 16px;
            background-color: #ebebeb;
            border: solid 1px #666;
            border-radius: $border-radius-primary;
            word-break: break-all;

            .pun {
                color: #999;
            }

            .str {
                // color: #007c8f;
                color: #fd8d25;
            }

            .lit {
                color: #00b347;
            }
        }
    }
}
</style>