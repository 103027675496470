<template>
    <div class="app-status" :class="[ status ]">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        status: { type: String, default: 'success' },
    }
}
</script>

<style lang="scss">
.app-status {
    padding: 12px 16px;

    border-radius: $border-radius-primary;

    @each $type in $table-tag-types {
        &.#{ $type } {
            background-color: map-get($table-tag-backgrounds, $type);

            border: solid 1px map-get($table-tag-colors, $type);
        }
    }
}
</style>