<template>
    <div class="page-asap-workflow-history">
        <div class="board">
            <div class="header">
                <app-dropdown-select
                    v-model="view_format"
                    :options="view_format_options"

                    key-value="value"
                    key-title="title"

                    label="View format"
                />

                <div class="actions">
                    <!-- <button class="btn btn-table-action action-secondary action-chart"  @click="showGraph"><span>Show graph</span></button> -->
                    <button class="btn btn-table-action action-secondary action-export" @click="download"><span>Export</span></button>
                </div>
            </div>

            <template v-if="view_format == 'compact'">
                <div class="events">
                    <app-status v-for="event in events" :key="event.activity_id"
                        :status="event.completed ? 'success' : 'danger' "
                    >
                        <div class="wrap">
                            <div class="col">Activity {{ event.activity_id }}: {{ event.name }}</div>

                            <div v-if="event.input" class="col">
                                <span class="title">Input</span>
                                <app-code :code="event.input.code" :collapsed="true" :title="event.input.title" />
                            </div>

                            <div v-if="event.result" class="col">
                                <span class="title">Result</span>
                                <app-code :code="event.result.code" :collapsed="true" :title="event.result.title" />
                            </div>
                        </div>
                    </app-status>
                </div>
            </template>
            <template v-else-if="view_format == 'json'">
                <app-prettify :code="code" />
            </template>
            <template v-else>
                <app-table
                    class="workflow-history-table"

                    :cols="cols"
                    :rows="rows"
                />
            </template>
        </div>
    </div>
</template>

<script>
import appStatus from '@/components/app-status'
import appCode from '@/components/app-code'
import appDropdownSelect from '@/components/app-dropdown-select'

import appPrettify from '@/components/app-prettify'

import appTable from '@/components/app-table'

import formatDate from '@/helpers/format-date'

export default {
    props: {
        response: { type: Object,  default: () => {} },
    },

    components: {
        appStatus,
        appCode,
        appDropdownSelect,

        appPrettify,

        appTable,
    },

    data() {
        return {
            view_format: 'compact',

            cols: [
                { key: 'id', title: 'ID', highlight: true },
                { key: 'type', title: 'Type', highlight: true },
                { key: 'time', title: 'elapsed/time', highlight: true },
                { key: 'details', title: 'summary/full details', type: 'details' },
            ],
        }
    },

    methods: {
        showGraph() {
            console.log('showGraph() {')
        },

        download() {
            console.log('download() {')
        },

        processData(code) {
            if (Array.isArray(code)) {
                for (let i = 0, len = code.length; i < len; i++) {
                    this.processData(code[i])
                }
            } else if (typeof code == 'object') {
                for (const key in code) {
                    if (key == 'payloads') {
                        for (let i = 0, len = code[key].length; i < len; i++) {
                            // code[key][i] = atob(code[key][i].data)
                            // code[key][i] = JSON.parse(atob(code[key][i].data))
                            code[key][i] = atob(code[key][i].data).replaceAll('\"', '')
                        }
                    } else {
                        this.processData(code[key])
                    }
                }
            }
        },
    },

    computed: {
        code() {
            let code = {}

            if (this.response) {
                code = JSON.parse(JSON.stringify(this.response))

                this.processData(code)
            }

            return code
        },

        rows() {
            const rows = []

            if (this.response) {
                const len = this.response.history.events.length

                for (let i = 0; i < len; i++) {
                    const event = this.response.history.events[i]

                    const type = Object.keys(event.Attributes)[0]
                        .replace(/_event_attributes$/, '')
                        .split('_')
                        .map(string => string.charAt(0).toUpperCase() + string.slice(1))
                        .join('')

                    const time = formatDate(new Date(event.event_time))

                    const details = []

                    const data = event.Attributes[Object.keys(event.Attributes)[0]]

                    for (const key in data) {
                        switch (key) {
                            case 'workflow_type': {
                                details.push({
                                    title: 'Name',
                                    type: 'word',
                                    data: {
                                        text: data[key].name,
                                    },
                                })
                            } break

                            case 'task_queue': {
                                details.push({
                                    title: 'TASKQUEUE',
                                    type: 'word',
                                    data: {
                                        text: data[key].name,
                                    },
                                })
                            } break

                            case 'input': {
                                details.push({
                                    title: 'INPUT',
                                    type: 'code',
                                    data: {
                                        code: atob(data[key].payloads[0].data),
                                        title: 'Input',
                                    },
                                })
                            } break

                            case 'result': {
                                details.push({
                                    title: 'RESULT',
                                    type: 'code',
                                    data: {
                                        code: atob(data[key].payloads[0].data),
                                        title: 'Result',
                                    },
                                })
                            } break

                            case 'identity': {
                                details.push({
                                    title: 'IDENTITY',
                                    type: 'word',
                                    data: {
                                        text: data[key],
                                    },
                                })
                            } break

                            case 'request_id': {
                                details.push({
                                    title: 'REQUESTID',
                                    type: 'word',
                                    data: {
                                        text: data[key],
                                    },
                                })
                            } break
                        }
                    }

                    rows.push({
                        uuid: event.event_id,
                        id: event.event_id,
                        type,
                        time,
                        details,
                    })
                }
            }

            return rows
        },

        events() {
            const events = []

            if (this.response) {
                const len = this.response.history.events.length

                const scheduled = {}
                const completed = {}

                for (let i = 0; i < len; i++) {
                    if (this.response.history.events[i].Attributes.activity_task_scheduled_event_attributes) {
                        const activity_id = this.response.history.events[i].Attributes.activity_task_scheduled_event_attributes.activity_id
                        const name = this.response.history.events[i].Attributes.activity_task_scheduled_event_attributes.activity_type.name

                        scheduled[activity_id] = {
                            activity_id,
                            name,
                        }

                        if (this.response.history.events[i].Attributes.activity_task_scheduled_event_attributes.input) {
                            scheduled[activity_id].input = {
                                code: atob(this.response.history.events[i].Attributes.activity_task_scheduled_event_attributes.input.payloads[0].data),
                                title: 'Input',
                            }
                        }
                    }

                    if (this.response.history.events[i].Attributes.activity_task_completed_event_attributes) {
                        const scheduled_event_id = this.response.history.events[i].Attributes.activity_task_completed_event_attributes.scheduled_event_id

                        completed[scheduled_event_id] = {
                            scheduled_event_id,
                        }

                        if (this.response.history.events[i].Attributes.activity_task_completed_event_attributes.result) {
                            completed[scheduled_event_id].result = {
                                code: atob(this.response.history.events[i].Attributes.activity_task_completed_event_attributes.result.payloads[0].data),
                                title: 'Result',
                            }
                        }
                    }
                }

                for (const id in scheduled) {
                    if (id in completed) {
                        scheduled[id].completed = true

                        scheduled[id] = {
                            ...scheduled[id],
                            ...completed[id],
                        }
                    } else {
                        scheduled[id].completed = false
                    }

                    events.push(scheduled[id])
                }
            }

            return events
        },

        view_format_options() {
            return [
                {
                    value: 'compact',
                    title: 'Compact',
                },
                {
                    value: 'grid',
                    title: 'Grid',
                },
                {
                    value: 'json',
                    title: 'JSON',
                },
            ]
        },
    },
}
</script>

<style lang="scss">
.page-asap-workflow-history {
    .app-code {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .board {
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        .header {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .app-dropdown-select {
                max-width: 192px;
            }

            .actions {
                display: flex;
                align-items: center;
                flex-shrink: 0;

                .btn {
                    display: flex;

                    width: auto;
                    height: 24px;

                    margin-right: 24px;

                    font-size: 18px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .workflow-history-table {
            margin-top: 24px;

            .app-table-box .rows .row {
                height: auto;
                min-height: 60px;
            }

            .app-table-box .rows .row .col {
                padding: $app-table-column-padding;
            }

            @include table-cols-width((50px, 225px, 150px, 900px), true);
        }

        .events,
        .app-prettify {
            margin-top: 24px;
        }

        .app-status {
            margin-bottom: 24px;
            padding: 12px 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .wrap {
            display: flex;
            flex-wrap: wrap;

            .col {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 0 8px;

                & > .title {
                    margin-right: 16px;
                }

                b {
                    font-weight: bold;
                }

                &:nth-child(1) {
                    flex: 0 0 25%;
                    max-width: 25%;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    flex: 0 0 37.5%;
                    max-width: 37.5%;
                }
            }
        }
    }
}
</style>